/********************************************
 * Functions to interface with MSAL 2.x
 ********************************************
 * Methods:
 * createMsalObj - create MSAL object
 * initMsalObj initialize MSAL object and calls MSAL obj handleRedirectPromise method
 *  to start authentication
 * handleResponse - Due to the fact that no MSAL obj methods can be called before
 *   handleRedirectPromise finishes, all MSAL flow and code are moved into this
 *   method that is guaranteed to be ran after handleRedirectPromise
 * handleRedirectPromiseError - any error handling of MSAL initialization goes here
 * logIn - log in a user
 * logOut - log out a user
 * getTokenRedirect - This method renew the token silently.
 * initialzeUserInfo -
 * routerControlBeforeAuthObject - This method was moved here from App.vue,
 *    so that to guarantee its run after handleRedirectPromise
 * checkTokenExpiration - check if token is expired, and renew it
 *    (and renew if necessary) on routing
 * addAxiosInterceptor - Adds axios requests interceptor to check token expiration
 *    (and renew if necessary) on sending requests
 * addIntervalTokenExpirationCheck - a process that runs regularly to check if token is still valid
 *    if it is not valid anymore, it sends the user to the timed out page.
 * findIfUserExist - Used for sign up form, to check if email entered by
 *    user has been registered or not
 * addNewUser
 ********************************************/

/////////////////// Imports ///////////////////
import * as Msal from "@azure/msal-browser";
import Axios from "axios";
import Store from "@/store/store";
import { useRouter } from "vue-router";

let mSALObj = {};
let accountId = null;
export const B2CManager = {
  computed: {
    mSALObj: {
      get: function () {
        return this.$store.getters.mSALObj;
      },
      set: function (newMSALObj) {
        mSALObj = newMSALObj;
        this.$store.dispatch("updateMSALObj", newMSALObj);
      }
    },
    userName: {
      get: function () {
        return this.$store.getters.userName;
      },
      set: function (newUserName) {
        this.$store.dispatch("updateUserName", newUserName);
      }
    },
    userEmail: {
      get: function () {
        return this.$store.getters.userEmail;
      },
      set: function (newUserEmail) {
        this.$store.dispatch("updateUserEmail", newUserEmail);
      }
    },
    userId: {
      get: function () {
        return this.$store.getters.userId;
      },
      set: function (newUserId) {
        this.$store.dispatch("updateUserId", newUserId);
      }
    },
    authAccountObj: {
      get: function () {
        return this.$store.getters.authAccountObj;
      },
      set: function (newAuthAccountObj) {
        this.$store.dispatch("updateAuthAccountObj", newAuthAccountObj);
      }
    },
    o360APIScope() {
      return this.$store.getters.o360APIScope;
    },
    clientId() {
      return this.$store.getters.clientId;
    },
    authority() {
      return this.$store.getters.authority;
    },
    routeDoesntRequireAuthorization() {
      const currentRoute = this.$router.currentRoute.value;
      return (
        currentRoute.name == "signup" ||
        currentRoute.name == "termsandconditions" ||
        currentRoute.name == "privacy" ||
        currentRoute.name == "timedout"
      );
    }
  },
  methods: {
    createMsalObj() {
      const msalConfig = {
        auth: {
          clientId: this.clientId,
          authority: this.authority,
          knownAuthorities: [process.env.VUE_APP_Known_Authority],
          // validateAuthority: false,
          postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
          // navigateToLoginRequestUrl: false,
          redirectUri: process.env.VUE_APP_URL,
          navigateToRequestUrl: true
        },
        cache: {
          cacheLocation: "localStorage",
          // storeAuthStateInCookie: true,
          storeAuthStateInCookie: false
        }
      };

      const mSALObj = new Msal.PublicClientApplication(msalConfig);

      return mSALObj;
    },
    async initMsalObj() {
      const vm = this;
      console.log("Inside initMsalObj: ", this.mSALObj);
      if (this.mSALObj == null) {
        this.mSALObj = this.createMsalObj();
        await mSALObj.initialize();
        console.log("created the following msal obj: ", this.mSALObj);
        await this.mSALObj
          .handleRedirectPromise()
          .then(this.handleResponse)
          .catch(this.handleRedirectPromiseError);
      }
    },
    async handleResponse(response) {
      /**
       * To see the full list of response object properties, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
       */
      await this.$router.isReady();
      console.log("in handle Auth. Response: ", response);
      await this.$router.isReady();
      if (response) {
        // if response contains an access token, store it
        this.selectAccount();
        if (response.accessToken && response.accessToken !== "") {
          const newExpChkProcessId = this.addIntervalTokenExpirationCheck(
            response.expiresOn,
            this.$store.getters.authTokenExpirationCheckProcessId
          );

          this.$store.dispatch("updateAuthToken", {
            accessToken: response.accessToken,
            expiresOn: response.expiresOn,
            expChkProcessId: newExpChkProcessId
          });
        }

        // for handling B2C user-flows and policies
        this.handlePolicyChange(response);

        Store.dispatch(
          "updateOnStartAppState",
          JSON.parse(response.accountState ? response.accountState : null)
        );
      } else {
        // this is needed for pages that doesn't need authetincation;
        //this.routerControlBeforeAuthObject(this.$router.currentRoute);
        if (this.routeDoesntRequireAuthorization) {
          // do nothing since we don't require authentication
        } else {
          this.selectAccount();
          this.getTokenRedirect();
        }
      }
      const temp = this.$router.currentRoute;
      const temp1 = this.$route;
      const temp2 = this.$router;
      this.routerControlBeforeAuthObject(this.$router.currentRoute);
      this.checkTokenExpiration(); // this causes a logout if token expired
      this.addAxiosInterceptor(); // check if token expired on axios requests
      this.addMouseEventsTokenCheck(); // check if token expired on mouse click (this is extreme but abviously we need this for bug 125421)
    },
    handleRedirectPromiseError(error) {
      console.log(error);

      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (
        error.errorMessage &&
        error.errorMessage.indexOf("AADB2C90118") > -1
      ) {
        try {
          mSALObj.loginRedirect({
            authority: process.env.VUE_APP_Reset_Password_Policy_URL
          });
        } catch (err) {
          console.log("err: ", err);
        }
      } else if (
        error.errorMessage &&
        error.errorMessage.indexOf("AADB2C90091") > -1
      ) {
        this.logIn();
      } else {
        this.logIn();
      }
    },
    handlePolicyChange(response) {
      /**
       * We need to reject id tokens that were not issued with the default sign-in policy.
       * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
       * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
       */
      if (
        process.env.VUE_APP_Reset_Password_Policy_URL.includes(
          response.idTokenClaims["tfp"]
        )
      ) {
        console.log("inside handle policy change: ", response);
        // window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
        this.mSALObj.logout();
      }
      // else if (response.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
      //   window.alert("Profile has been updated successfully. \nPlease sign-in again.");
      //   this.mSALObj.logout();
      // }
    },
    selectAccount() {
      /**
       * See here for more information on account retrieval:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
       */
      const currentAccounts = this.mSALObj.getAllAccounts();
      if (currentAccounts.length === 0) {
        console.log("No account found.");
        return;
      } else if (currentAccounts.length > 1) {
        // Add your account choosing logic here, right now it takes the first account info
        accountId = currentAccounts[0].homeAccountId;
        this.userName = currentAccounts[0].name;
        this.authAccountObj = currentAccounts[0];
        console.log("Multiple accounts detected.");
      } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId;
        this.userName = currentAccounts[0].name;
        this.authAccountObj = currentAccounts[0];
      }
    },
    logIn() {
      // if (this.mSALObj == null) {
      //   // if mSALObj at this point is still null, then there is an issue
      //   // reload the app
      //   window.location = process.env.VUE_APP_URL;
      //   return;
      // }

      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */
      const requestObj = {
        scopes: ["openid", this.o360APIScope, "offline_access"],
        // scopes: ["openid", this.o360APIScope],
        state: JSON.stringify(this.$store.getters.onStartAppState)
      };

      this.mSALObj.loginRedirect(requestObj);
    },
    logOut: function () {
      const vm = this;

      /* display warning message when changes are made are not saved before logging out */
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (
          vm.$store.getters.getProfileDataDirty ||
          vm.$store.getters.getPreferencesChanged ||
          vm.$store.getters.umProfileDirty ||
          vm.$store.getters.getGroupDetailDirty ||
          vm.$store.getters.getMVVisibilityHasChanges ||
          vm.$store.getters.getOVVisibilityHasChanges ||
          vm.$store.getters.getMVLayersHasChanges ||
          vm.$store.getters.getTilingHasChanges ||
          vm.$store.getters.updateMaintenanceRecordsDirty ||
          vm.$store.getters.getScheduleExportsHasChanges
        ) {
          // For IE and Firefox prior to version 4
          if (e) {
            e.returnValue = "Changes you made may not be saved.";
          }

          // For Safari
          else {
            return "Changes you made may not be saved.";
          }
        }
      };

      /* log user logging out if they are an external user */
      if (Store.getters.userInfo || !this.$store.getters.isCustomer) {
        this.$store.dispatch("logger", {
          LoggingCategory: "Information",
          Action: "Logged out"
        });
      }

      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */

      // Choose which account to logout from by passing a homeAccountId.
      const account = this.mSALObj.getAccountByHomeId(accountId);
      if (account) {
        const logoutRequest = {
          account: account
        };
        this.mSALObj.logoutRedirect();
      } else {
        this.mSALObj.logoutRedirect();
      }
    },
    getTokenRedirect(notFirstTime) {
      /**
       * See here for more info on account retrieval:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
       */
      const account = this.mSALObj.getAccountByHomeId(accountId);
      console.log("Account inside getTokenRedirect: ", account);
      const tokenRequest = {
        //scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
        scopes: [this.o360APIScope],
        state: JSON.stringify(this.$store.getters.onStartAppState),
        forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
        account: this.mSALObj.getAccountByHomeId(accountId)
      };
      console.log("tokenRequest: ", tokenRequest);

      // if (!account) {
      //   this.logIn();
      // }

      return this.mSALObj
        .acquireTokenSilent(tokenRequest)
        .then(response => {
          console.log("inside aquireTokenSilentCallback: ", response);
          // In case the response from B2C server has an empty accessToken field
          // throw an error to initiate token acquisition
          if (!response.accessToken || response.accessToken === "") {
            throw new Msal.InteractionRequiredAuthError();
          }
          const newExpChkProcessId = this.addIntervalTokenExpirationCheck(
            response.expiresOn,
            this.$store.getters.authTokenExpirationCheckProcessId
          );
          return this.$store.dispatch("updateAuthToken", {
            accessToken: response.accessToken,
            expiresOn: response.expiresOn,
            expChkProcessId: newExpChkProcessId,
            notFirstTime: notFirstTime
          });
          // return this.handleResponse(response);
        })
        .catch(error => {
          console.log(error);
          console.log(
            "silent token acquisition fails. acquiring token using redirection"
          );
          if (
            error instanceof Msal.InteractionRequiredAuthError ||
            error instanceof Msal.BrowserAuthError
          ) {
            // fallback to interaction when silent call fails

            return mSALObj.acquireTokenRedirect(tokenRequest);
          } else {
            console.log("inside catch else in getTokenRedirect");
          }
        });
    },
    async checkTokenExpiration(message) {
      const authTokenExpirationInSeconds = this.$store.getters
        .authTokenExpiresOn
        ? this.$store.getters.authTokenExpiresOn.getTime() / 1000
        : null;
      const currentTime = new Date().getTime() / 1000;
      if (
        // if token is within 15 of its expiration, get a new one (900 seconds is 15 minutes)
        authTokenExpirationInSeconds - currentTime < 900 &&
        !this.routeDoesntRequireAuthorization
      ) {
        console.log(message);
        await this.getTokenRedirect(true);
      }
    },
    // async checkTokenExpirationInInterceptor() {
    //   let authTokenExpirationInSeconds = this.$store.getters.authTokenExpiresOn
    //     ? this.$store.getters.authTokenExpiresOn.getTime() / 1000
    //     : null;
    //   let currentTime = new Date().getTime() / 1000;
    //   if (
    //     authTokenExpirationInSeconds - currentTime < 900 &&
    //     !this.routeDoesntRequireAuthorization
    //   ) {
    //     console.log("renewing token because of an api call");
    //     await this.getTokenRedirect(true);
    //   }
    // },
    addAxiosInterceptor() {
      //Add a response interceptor
      // Axios.interceptors.response.use((response) => {
      //   return response
      // }, function (error) {
      //   console.log("inside axios interceptor error catch");
      //   console.log(error);
      //   if (error.response && error.response.status === 401) {
      //     mSALObj.logout();
      //   }
      //   return Promise.reject(error); // causes the error modal to display
      // });
      const checkTokenExpirationCopy = this.checkTokenExpiration;
      Axios.interceptors.request.use(
        function (config) {
          checkTokenExpirationCopy("Renewing token on an api call");
          return config;
        },
        function (error) {
          //return Promise.reject(error);
          return error;
        }
      );
    },
    addMouseEventsTokenCheck() {
      const checkTokenExpirationCopy = this.checkTokenExpiration;
      window.addEventListener("click", function (e) {
        checkTokenExpirationCopy("Renewing token on a mouse action");
      });
    },
    addIntervalTokenExpirationCheck(authTokenExpirationInSeconds, processId) {
      const check = () => {
        const AuthTokenExpirationInSeconds = this.$store.getters
          .authTokenExpiresOn
          ? this.$store.getters.authTokenExpiresOn.getTime() / 1000
          : null;
        const currentTime = new Date().getTime() / 1000;

        console.log(
          "inside token expiration interval check: ",
          AuthTokenExpirationInSeconds,
          " time left: ",
          AuthTokenExpirationInSeconds - currentTime
        );
        if (
          AuthTokenExpirationInSeconds &&
          AuthTokenExpirationInSeconds - currentTime < 0
        ) {
          // this.logOut();
          // remove the first forward slash first
          const lastPage =
            // this.$router.currentRoute.path &&
            // this.$router.currentRoute.path[0] == "/"
            //   ? this.$router.currentRoute.path.substring(1)
            //   : this.$router.currentRoute.path;
            this.$router.currentRoute.value.name;
          if (lastPage != "timedout") {
            this.$router.push({
              name: "timedout",
              query: { lastPage: lastPage }
            });
          }
        }
      };
      // console.log("clearing the following exp check process: ", processId);
      clearTimeout(processId);
      authTokenExpirationInSeconds = authTokenExpirationInSeconds
        ? authTokenExpirationInSeconds.getTime() / 1000
        : null;
      const currentTime = new Date().getTime() / 1000;
      console.log(
        "authTokenExpirationInSeconds: ",
        authTokenExpirationInSeconds
      );
      console.log("currentTime: ", currentTime);

      const newProcessId = setTimeout(
        check,
        (authTokenExpirationInSeconds - currentTime + 1) * 1000
      );
      // console.log("New token exp check process id: ", newProcessId);
      console.log(
        "Next check in: ",
        authTokenExpirationInSeconds - currentTime + 1,
        "s"
      );
      return newProcessId;
    },
    initialzeUserInfo() {
      const account = this.mSALObj.getAllAccounts()
        ? this.mSALObj.getAllAccounts()[0]
        : {};
      this.userName = account.name;
      this.userId = account.idTokenClaims.oid;
      this.userEmail = account.idTokenClaims.emails
        ? account.idTokenClaims.emails[0]
        : null;
      // this.getAuthTokenForUser();
    },
    async findIfUserExist(emailAddress) {
      const URL = `${this.$store.getters.findIfUserExistURL}${emailAddress}`;
      const authToken = this.$store.getters.authToken;
      return Axios.get(
        URL,
        authToken
          ? {
              headers: { Authorization: `Bearer ${authToken}` }
            }
          : undefined
      )
        .then(response => {
          // callback(callbackName, response.data);
          // console.log(response.data);
          if (response.data == "User does not exists") {
            return true;
          } else if (response.data == "User Already Exists") {
            return false;
          } else {
            throw "Error";
          }
        })
        .catch(error => {
          console.log("There was an error:");
          console.dir(error);
          const errorObj = {
            requestName: "findIfUserExist",
            errorMessage:
              "We're sorry, we encountered an error validating your email address. Please try again. If this error continues to occur, please contact support.",
            Error: error
          };
          Store.dispatch("updateError", errorObj);
          return true;
        });
    },
    addNewUser(userData) {
      const URL = this.$store.getters.addNewUserURL;
      const authToken = this.$store.getters.authToken;
      Axios.post(URL, {
        headers: { Authorization: `Bearer ${authToken}` },
        ...userData
        // eslint-disable-next-line
      }) // .then(response => {
        //console.log("User added! directing to login page...");
        // setTimeout(() => this.logIn(), 10000);

        //  })
        .catch(error => {
          // console.log("There was an error:"); // Logs out the error
          // console.dir(error);
          //var errorObj = {
          //  requestName: "getApplicationRegistry",
          //  Error: error
          //  };
          //Store.dispatch("updateError", errorObj);
        });
    },
    routerControlBeforeAuthObject(currentRoute) {
      this.$store.dispatch("updateOnStartAppRoute", currentRoute.value.path);
      const account = this.mSALObj ? this.mSALObj.getAllAccounts()[0] : null;
      console.log("Account value in routerControl: ", account);
      // user not logged in
      if (!account) {
        console.log("no logged in user");
        // alow the following pages to be displayed, and redirect to login for all others
        if (
          currentRoute.value.name != "signup" &&
          currentRoute.value.name != "termsandconditions" &&
          currentRoute.value.name != "privacy" &&
          currentRoute.value.name != "timedout"
        ) {
          this.$store.dispatch(
            "updateOnStartAppState",
            currentRoute.value.query
          );
          // if reset password request AADB2C90118, just don't do anything and let b2cManager
          // msal's handleRedirectCallback deal with it
          if (currentRoute.fullPath.indexOf("AADB2C90118") == -1) {
            // alert(currentRoute.fullPath);
            this.logIn();
          }
        } else {
          console.log("Page does not need authorization");
        }

        // user logged in
      } else {
        this.initialzeUserInfo();

        // if (currentRoute.name == "logout") {
        //   this.logOut();
        if (
          currentRoute.value.name == "termsandconditions" ||
          currentRoute.value.name == "privacy" ||
          currentRoute.value.name == "timedout"
        ) {
          // do nothing
        } else if (currentRoute.value.name != "loading") {
          // else display a loading page while userAuthObj is being gotten from ther auth service
          this.$router.push({ name: "loading" });
        }
      }
    }
  }
};
