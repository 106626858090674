import { defineStore } from "pinia";
import { useModal } from "bootstrap-vue-next";

type ModalActions = {
  show: () => void;
  hide: () => void;
};

type BvModalType = {
  [key: string]: ModalActions;
};

// Total 75 modals
const sharedModalIds = ["DBDModal", "DBDModalPD"];
const contactModalsIds = ["viewMap"];
const mapViewModalsIds = [
  // 6
  "approve-truss-solution-modal",
  "DBDModal",
  "bulk-update-maintenances",
  "modal-export",
  "export-modal",
  "advanced-search",
  "resiliency-update-status-modal"
];
const oCalcProModalsIds = ["tranferCompleted", "tranferLicense"];
const osmoseServicesModalsIds = ["notMemberPG"];
const pdModalsIds = ["pd-pdf-modal", "DBDModalPD", "DBDModalPDFP"];

const productsModalsIds = [
  // 10
  "manageProducts",
  "Dictionary",
  "approveConformation",
  "rejectConformation",
  "uploadConfirm",
  "approveConfirm",
  "rejectConfirm",
  "payCCard",
  "openViewdetails",
  "returnPolicy"
];

//CustomReferenceLayer.vue uses dynamic id. Follow it and see what it is.
const pgmModalsIds = [
  // 5
  "delete-schedule",
  "modal-schedule",
  "success-message",
  "emailListModal",
  "modal-add-tables",
  "modal-add-ov-tables",
  "selectJobNoModal"
];
const osmoViewModalsIds = [
  "modal-export",
  "export-modal",
  "advanced-search-dv",
  "export-modal-dv"
];
const jointUseModalsIds = [
  // 42
  "successMessage",
  "noFilesApplication",
  "ju-notification-modal",
  "extensionsSelect",
  "success-message",
  "copyPopup",
  "auditCompleteModal",
  "ConfirmationMsgEditedApplication",
  "historyTable",
  "replaceFile",
  "copySuccessfulPopup",
  "resubmitSuccessful",
  "attachmentsTable",
  "manualApprovalModal",
  "holdModal",
  "resumeModal",
  "denialModal",
  "cancelModal",
  "resetShotClockModal",
  "appHistory",
  "resubmitCopyPopup",
  "SavePopup",
  "SaveSuccessPopup",
  "structureFiles",
  "downloadFormat",
  "ConformationMsg",
  "multipleApplication",
  "popUpError",
  "deletePopup",
  "createCopyPopup",
  "resubmitPopup",
  "ConformationMsg1",
  "editFormCancelModal",
  "rejectModal",
  "historyTableCreate",
  "newApplication",
  "resubmitSuccessfulPopup",
  "deleteSuccessPopup",
  "acceptModal",
  "polesFromPortalAcceptModal",
  "polesFromPortalSubmitModal",
  "structureError"
];
export const useBootstrapVueStore = defineStore("BootstrapVueStore", {
  state: (): {
    modalIds: Record<string, string[]>;
    bvModal: BvModalType;
  } => {
    return {
      modalIds: {
        Shared: sharedModalIds,
        Contact: contactModalsIds,
        MapViewV2: mapViewModalsIds,
        OCalcPro: oCalcProModalsIds,
        OsmoseServices: osmoseServicesModalsIds,
        Products: productsModalsIds,
        ProjectDashboard: pdModalsIds,
        ProjectGroupManagement: pgmModalsIds,
        OsmoView: osmoViewModalsIds,
        JointUse: jointUseModalsIds
      },
      bvModal: {}
    };
  },
  getters: {
    getBVModal(): BvModalType {
      return this.bvModal;
    }
  },
  actions: {
    setBVModal(bvModal: BvModalType) {
      this.bvModal = bvModal;
    },
    addBVModal(bvModal: BvModalType) {
      this.bvModal = { ...this.bvModal, ...bvModal };
    },
    registerAppModals(appName: string) {
      const modalsIds = this.modalIds[appName];
      const bvModals: BvModalType = {};
      modalsIds.forEach(modalId => {
        const modal = useModal(modalId);
        bvModals[modalId] = modal;
      });
      this.addBVModal(bvModals);
    },
    show(modalName: string) {
      this.bvModal[modalName]?.show();
    },
    hide(modalName: string) {
      this.bvModal[modalName]?.hide();
    }
  }
});
